<template>
  <div>
    <h2>Affichage datas PPG</h2>
    <websocket-simulation />
  </div>
</template>
<script>
import WebsocketSimulation from "../../components/cloud/websocketSimulation.vue";
export default {
  components: {
    WebsocketSimulation,
  },
};
</script>
