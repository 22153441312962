<template>
  <div class="dashboard">
    <h1 class="title">Données en Temps Réel</h1>
    <div v-if="Object.keys(tagsData).length" class="cards-container">
      <div v-for="(data, tagId) in tagsData" :key="tagId" class="card">
        <h2>{{ tagId }}</h2>
        <ul>
          <li v-for="(value, key) in data" :key="key">
            <span class="key">{{ key }}</span
            >: <span class="value">{{ value.toFixed(2) }}</span>
          </li>
        </ul>
      </div>
    </div>
    <p v-else class="no-data">En attente de données...</p>
  </div>
</template>

<script>
import { createWebSocketConnection } from "@/services/webSocketService.js";
import { getTags } from "@/functions/get-data.js";

export default {
  data() {
    return {
      socketController: null,
      tagsData: {},
    };
  },
  methods: {
    connectWebSocket(tags, url) {
      this.socketController = createWebSocketConnection(url, tags, (data) => {
        if (data && data.tagId && data.data) {
          this.tagsData = {
            ...this.tagsData,
            [data.tagId]: data.data,
          };
        }
      });
    },
    getWebSocketUrl() {
      const protocol = window.location.protocol === "https:" ? "wss://" : "ws://";
      const hostname = window.location.hostname;

      // Si on est en local avec IP ou localhost
      if (hostname === "localhost" || /^\d+\.\d+\.\d+\.\d+$/.test(hostname)) {
        return `${protocol}${hostname}:8081`; // WS local sur 8081
      }

      // Sinon, on est en prod (micetracking.com)
      return `${protocol}${hostname}/ws/`; // WS proxifié via Nginx en prod
    },
  },
  async mounted() {
    const tags = await getTags();
    const url = await this.getWebSocketUrl();
    console.log(url);
    this.connectWebSocket(tags.implant, url);
  },
  beforeUnmount() {
    if (this.socketController) {
      this.socketController.close();
    }
  },
};
</script>

<style scoped>
.dashboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.title {
  font-size: 24px;
  margin-bottom: 20px;
}

.cards-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  width: 100%;
  max-width: 800px;
}

.card {
  background: #fff;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.card:hover {
  transform: scale(1.05);
}

h2 {
  font-size: 18px;
  margin-bottom: 10px;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
  font-size: 16px;
}

.key {
  font-weight: bold;
  color: #333;
}

.value {
  color: #007bff;
}

.no-data {
  font-size: 18px;
  color: #666;
}
</style>
