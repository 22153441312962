/**
 * Crée et gère automatiquement un WebSocket avec logique de reconnexion.
 *
 * @param {String} url - L'URL du WebSocket (ex: ws://localhost:8080)
 * @param {Array} capteurs - Liste des capteurs attribués à l'utilisateur
 * @param {Function} onMessage - Callback appelée à la réception d'un message
 * @param {Function} [onOpen] - Callback facultative, appelée à l'ouverture du socket
 * @param {Function} [onClose] - Callback facultative, appelée à la fermeture du socket
 * @param {Number} [reconnectDelay=5000] - Délai de reconnexion automatique (en ms)
 * @returns {Object} Un objet contenant la socket courante et une méthode pour la fermer
 */
export function createWebSocketConnection(
  url,
  capteurs = [],
  onMessage,
  onOpen = () => {},
  onClose = () => {},
  reconnectDelay = 5000
) {
  let socket = null;

  function connect() {
    socket = new WebSocket(url);

    socket.onopen = () => {
      console.log('WebSocket connecté à', url, 'et ' , JSON.stringify({ capteur_attributed: capteurs }));
      // Envoyer les capteurs attribués lors de la connexion
      socket.send(JSON.stringify({ capteur_attributed: capteurs }));
      onOpen();
    };

    socket.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        onMessage(data);
      } catch (error) {
        console.error('Erreur lors du parsing du message WebSocket :', error);
      }
    };

    socket.onclose = () => {
      console.log(`WebSocket déconnecté de ${url}. Nouvelle tentative dans ${reconnectDelay / 1000} secondes...`);
      onClose();
      setTimeout(connect, reconnectDelay);
    };
  }

  // Initialisation
  connect();

  return {
    /**
     * Ferme le socket pour éviter la reconnexion
     */
    close() {
      if (socket) {
        onClose = () => {};
        clearTimeout();
        socket.close();
      }
    }
  };
}
